import React, { Component } from 'react'
import {
    Modal,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";

import firebase from '../../firebase.js'
import Notify from 'react-notification-alert';
import { Redirect } from 'react-router-dom';

export class RecycleModal extends Component {

    constructor(props){
        super(props);

        this.state = {
            isLoading: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});

        firebase.database().ref().child('leads-rh').child(this.props.email.toString().split('.').join("")).update(
            {
                
                excluido: false,
                responsavel: this.props.user.apelido,
                unixTimestamp: new Date().getTime() / 1000,
                
            }, (error) => {
                if (!error){
                    this.setState({isLoading: false});
                    window.location.reload();
                }
                else
                    alert(error)
            }
        );
    }

    render() {
        return (
            <div className='content'>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggleEditModal} onOpened={this.onOpened}>
                    <form style={{margin:'50px', textAlign:'center'}}>
                        <h3>Tem certeza que gostaria de reciclar {this.props.nome.toString()}?</h3>
                        <Button type="" color="warning" onClick={this.props.closeModal}>Cancelar</Button>
                        <Button type="submit" color="info" onClick={this.handleSubmit} disabled={this.state.isLoading}>
                            {this.state.isLoading ? 'Carregando...' : 'Reciclar'}
                        </Button>
                    </form>
                </Modal>
            </div>
        )
    }
    
}

export default RecycleModal
