import React, { Component } from 'react'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardFooter from 'reactstrap/lib/CardFooter'
import CardTitle from 'reactstrap/lib/CardTitle'
import Col from 'reactstrap/lib/Col'
import FormGroup from 'reactstrap/lib/FormGroup'
import Input from 'reactstrap/lib/Input'
import Label from 'reactstrap/lib/Label'
import Row from 'reactstrap/lib/Row'
import Button from 'reactstrap/lib/Button'
import firebase from '../firebase.js';

export default class EditPass extends Component {

    constructor(props){
        super(props);
        this.state = {
            oldPass: '',
            newPass: '',
            newPassConfirm: '',

            loading: false,
        }
        console.log(props.user)
    }

    reauthenticate (currentPassword) {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }

    changePassword (currentPassword, newPassword) {
        this.reauthenticate(currentPassword).then(() => {
            var user = firebase.auth().currentUser;
            user.updatePassword(newPassword).then(async () => {
                await firebase.database().ref().child('usuarios').child(this.props.user.email.toString().split('.').join("")).update({
                    password: this.state.newPass,
                    lastModTimestamp: Date.now()
                });
                this.setState({
                    oldPass: '',
                    newPass: '',
                    newPassConfirm: '',
                    loading: false,
                })
                alert('Senha atualizada com sucesso!');
            }).catch((error) => {
                this.setState({loading:true});
                if(error.code == 'auth/wrong-password')
                    alert('Senha atual incorreta.');
                else{
                    console.log(error);
                    alert(error.message);
                }
            });
        }).catch((error) => {
            this.setState({loading:true});
            if(error.code == 'auth/wrong-password')
                alert('Senha atual incorreta.');
            else{
                console.log(error);
                alert(error.message);
            }
        });
    }

    handleChange = (e) => {
        let change = {};
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.oldPass != '' && this.state.newPass != '' && this.state.newPassConfirm != ''){
            if(this.state.newPass === this.state.newPassConfirm){
                this.setState({loading:true});

                this.changePassword(this.state.oldPass, this.state.newPass)


            }else{
                alert('A senha deve ser igual à confirmação de senha.')
            }

        }else{
            alert('Preencha todos os campos.')
        }
    }

    render() {
        return (
            <div className="content">
                <Row>
                    <Col lg="4" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-circle-10 text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Usuário</p>
                                        <CardTitle tag="p">{this.props.user.apelido}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-email-85 text-info" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Email</p>
                                        <CardTitle tag="p" style={{fontSize:'1.2rem'}}>{this.props.user.email}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col lg="4" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-badge text-warning" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Acesso</p>
                                        <CardTitle tag="p">{this.props.user.permissoes}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row>
                <Col xs={12}>
                        <Card className="card-stats" style={{textAlign:'center'}}>
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning numbers">
                                        <i className="nc-icon nc-key-25 text-warning" />
                                        
                                        <p className="card-category">Alterar sua Senha</p>
                                        <CardTitle tag="p">Preencha o formulário</CardTitle>
                                        <p />
                                    </div>
                                    </Col>

                                    
                                    <Col md="8" xs="7">
                                        <form style={{margin:'50px',}}>
                                            <div className="form-row">
                                            <FormGroup className="col-md-4">
                                                <Label for="password">Senha Atual</Label>
                                                <Input type="password"  placeholder="Senha Atual..." value={this.state.oldPass} onChange={this.handleChange} name='oldPass' />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="password">Nova Senha</Label>
                                                <Input type="password"  placeholder="Nova Senha..." value={this.state.newPass} onChange={this.handleChange} name='newPass' />
                                            </FormGroup>
                                            <FormGroup className='col-md-4'>
                                                <Label for="password-confirm">Confirmação</Label>
                                                <Input type="password" placeholder='Confirme a Nova Senha...' value={this.state.newPassConfirm} onChange={this.handleChange} name='newPassConfirm' />
                                            </FormGroup>
                                            </div>       
                                            <FormGroup className='col-md-12'>                               
                                                <Button type="submit" color="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                                                    {this.state.loading ? 'Carregando...' : 'Salvar'}
                                                </Button>
                                            </FormGroup>
                                        </form>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
