/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import firebase from '../firebase.js';

import routes from "routes.js";
import Login from "views/pages/Login.jsx";
import Leads from "views/Leads.jsx";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user:{
        nome: 'Usuário',
        apelido: 'Usuário',
        email: '',
        permissoes: '',
        uid: '',
        telefone: '',
        loggedIn: false,
      },
      usuariosArray: [],
      leadsArray: [],
      excluidos: [],

      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      
      loading: true,

    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    //Firebase
    
    let self = this;
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({loading: true})
      if(user){
        firebase.database().ref().child('usuarios').orderByChild('uid').on('child_added', (snap) => {
        
          if(snap.val().uid == user.uid){
            if(snap.val().permissoes == 'Administrador'){
              firebase.database().ref().child('leads-rh').on('value', (snap2) => {
                if(snap2.val()){
                  self.setState({leadsArray: Object.values(snap2.val())},
                  ()=>{
                    firebase.database().ref().child('leads-rh').orderByChild('excluido').equalTo(true).on('value', (excluidos) => {
                      if(excluidos.val())
                        self.setState({excluidos: Object.values(excluidos.val()), loading: false})                      
                      else
                        self.setState({loading: false})
                    });
                  }
                  )
                  console.log(snap2.val())
                }
                else
                  self.setState({loading: false})
              });        

            }else{
              firebase.database().ref().child('leads-rh').orderByChild('responsavel').equalTo(snap.val().apelido).on('value', (snap3) => {
                if(snap3.val())
                  self.setState({leadsArray: Object.values(snap3.val())},
                  () => {
                    firebase.database().ref().child('leads-rh').orderByChild('excluido').equalTo(true).on('value', (excluidos) => {
                      if(excluidos.val())
                        self.setState({excluidos: Object.values(excluidos.val()), loading: false})
                      else                       
                        self.setState({loading: false})
                    });
                  })
                  else
                    self.setState({loading: false})
              });          
            }

            self.setState({
                user:{
                  apelido: snap.val().apelido,
                  email: snap.val().email,
                  nome: snap.val().nome,
                  permissoes: snap.val().permissoes,
                  telefone: snap.val().telefone,
                  timestamp: snap.val().timestamp,
                  uid: snap.val().uid,
                  loggedIn: true,
                },
                usuariosArray: [...this.state.usuariosArray, snap.val()]
              })
            }else{
              self.setState({usuariosArray: [...this.state.usuariosArray, snap.val()]})
            }
        });
      }else{        
        this.setState({user:{loggedIn: false}, loading: false});
      }
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      
      let elementWithClassName =
      React.cloneElement(React.createElement(prop.component), { user: this.state.user, usuariosArray: this.state.usuariosArray, leadsArray: this.state.leadsArray, excluidos: this.state.excluidos })
      return (
          <Route
            path={prop.path}
            render={() => elementWithClassName}
            key={key}
          />
        );
      
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    if(!this.state.loading){
      if(this.state.user.loggedIn){
        return (
          <div className="wrapper">
            <Sidebar
              {...this.props}
              routes={routes}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
              user={this.state.user}
            />
            <div className="main-panel" ref="mainPanel">
              <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
              <Switch>{this.getRoutes(routes)}</Switch>
              {// we don't want the Footer to be rendered on full screen maps page
              this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : null}
            </div>
          </div>
        );
      }else{
        return(
          <div className="wrapper">
            <div className="" ref="mainPanel">
              <Login />
            </div>
          </div>
        );
      }
    }else{
      return(
        <div className="wrapper" style={{position: 'relative', margin: 'auto', textAlign:'center',}}>
            <div className="" ref="mainPanel">
              <h1 style={{position: 'absolute', top:'50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <i className='fa fa-spinner fa-spin fa-3x' style={{
                  color: '#03bcff'
                }} />
              </h1>
            </div>
          </div>
      )
    }
  }
}

export default Admin;