import React, { Component, useEffect } from "react";
import firebase from "firebase";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function AddLeadAPI() {
  let query = useQuery();

  const uploadAndRedirect = () => {
    let userName = query.get("userName");
    let userEmail = query.get("userEmail");
    let userPhone = query.get("userPhone");
    let userCreci = query.get("userCreci");
    let agendamento = query.get("agendamento");
    let origem = query.get("origem");

    if (userName && userEmail && userPhone && userCreci && origem) {
      let sorteioRef = firebase.database().ref().child("sorteio");
      sorteioRef.once("value", (snap) => {
        if (snap) {
          const key = firebase
            .database()
            .ref()
            .child("leads-rh")
            .child(userEmail.toString().split(".").join(""))
            .set(
              {
                nome: userName,
                email: userEmail,
                phone: userPhone,
                status: "Sem Contato",
                agendamento: agendamento ? agendamento : "Sem Agendamento",
                excluido: false,
                diretor: snap.val().diretores[0],
                responsavel: snap.val().rh[0],
                creci: userCreci,
                origem: origem,
                unixTimestamp: new Date().getTime() / 1000,
              },
              (error) => {
                if (error) {
                  console.error(error);
                  return error;
                } else {
                  sorteioRef.set({
                    diretores: {
                      0: snap.val().diretores[1],
                      1: snap.val().diretores[2],
                      2: snap.val().diretores[3],
                      3: snap.val().diretores[4],
                      4: snap.val().diretores[5],
                      5: snap.val().diretores[0],
                    },
                    rh: {
                      0: snap.val().rh[1],
                      1: snap.val().rh[0],
                    },
                  });
                  console.log("Success, " + query.get("userName"));
                  return "success";
                }
              }
            );
        }
      });
    }
  };
  useEffect(() => {
    uploadAndRedirect();
  }, []);

  return <div></div>;
}
/*
export class AddLeadAPI extends Component {

    componentDidMount() {
        this.uploadAndRedirect() 
    }
    

    uploadAndRedirect = () => {
        if(
            userName &&
            userEmail &&
            userPhone &&
            userCreci &&
            agendamento
        )
        {
            let self = this;


            let sorteioRef = firebase.database().ref().child('sorteio');
            sorteioRef.once('value', (snap) => {
                if(snap){
                    const key = firebase.database().ref().child('leads-rh').child(userEmail.toString().split('.').join("")).set({
                        nome: userName,
                        email: userEmail,
                        phone: userPhone,
                        status: 'Sem Contato',
                        agendamento: agendamento ? agendamento : 'Sem Agendamento',
                        excluido: false,
                        diretor: snap.val().diretores[0],
                        responsavel: snap.val().rh[0],
                        creci: userCreci,
                        origem: origem,
                        unixTimestamp: new Date().getTime() / 1000,
                    }, (error) => {
                        if(error)
                            return(error)
                        else{
                            
                                sorteioRef.set({
                                    diretores:{
                                        0: snap.val().diretores[1],
                                        1: snap.val().diretores[2],
                                        2: snap.val().diretores[0],
                                    },
                                    rh:{
                                        0: snap.val().rh[1],
                                        1: snap.val().rh[0],                                        
                                    }
                                });                
                            return('success')
                        }
                    });
                }
            });
            

            
        }
    }
    
    render() {
        return (
            <div>
            </div>
        )
    }
}

export default AddLeadAPI

*/
