import React from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Badge,
  Row,
  Col,
  Button,
} from 'reactstrap';


import { Bar } from "react-chartjs-2";
import {Link} from "react-router-dom";

export default function StaticBarChartView(props) {

  const chartOptions = {
    data: {
      labels: props.labels,
      datasets: props.dataset,
    },
    options: {
      legend: {
        display: true
      },
  
      tooltips: {
        enabled: true
      },
  
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 5
              //padding: 20
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: "rgba(255,255,255,0.05)"
            }
          }
        ],
      }
    }
  };

  const parseBadge = (n1, n2) => {
    return Math.round(((n1 - n2) / n2) * 100);
  }

    return (
        <Card>
            <CardHeader>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    {props.title}
                  </h6>
                  <Bar
                    data={chartOptions.data}
                    options={chartOptions.options}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                    <Row>
                      <Col sm="7">
                        <p className="footer-title">{props.linkText}</p>
                      </Col>
                      <Col sm="5">
                        <div className="pull-right">
                          <Link to={props.to} style={{textDecoration:'none', fontColor:'#9f9f9f'}}>
                            <Button
                              className="btn-round btn-icon"
                              color={props.color}
                              size="sm"
                            >
                              <i className="fa fa-play" />
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                </CardFooter>
              </Card>
    )
}
