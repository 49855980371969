/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Leads from "views/Leads";
import Usuarios from "views/Usuarios";
import Excluidos from "views/Excluidos";
import NewLeadModal from "views/Leads/NewLeadModal";
import EditPass from "views/EditPass";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "",
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "nc-icon nc-circle-10",
    component: Leads,
    layout: ""
  },
  {
    path: "/usuarios",
    name: "Usuários",
    icon: "nc-icon nc-circle-10",
    component: Usuarios,
    layout: "",
    adminOnly: true,
  },
  {
    path: "/excluidos",
    name: "Excluídos",
    icon: "fa fa-trash",
    component: Excluidos,
    layout: "",
    adminOnly: false,
  },
  {
    path: "/editar-senha",
    name: "Editar Senha",
    icon: "fa fa-key",
    component: EditPass,
    layout: "",
    adminOnly: false,
    invisible: true,
  },
  /*{
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/meus-clientes",
    name: "Meus Clientes",
    icon: "nc-icon nc-circle-10",
    component: MeusClientes,
    layout: "/admin"
  },
  {
    path: "/meus-lembretes",
    name: "Meus Lembretes",
    icon: "nc-icon nc-bell-55",
    component: MeusLembretes,
    layout: "/admin"
  },
  {
    path: "/empreendimentos",
    name: "Empreendimentos",
    icon: "nc-icon nc-bank",
    component: Empreendimentos,
    layout: "/admin"
  },
  {
    path: "/cliente/:userId",
    name: "Cliente",
    icon: "nc-icon nc-single-02",
    component: Cliente,
    layout: "/admin"
  },*/
  /*{
    collapse: true,
    name: "Empreendimentos",
    icon: "nc-icon nc-bank",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Oferta Ativa",
    icon: "nc-icon nc-headphones",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Analytics",
    icon: "nc-icon nc-chart-bar-32",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Mapa",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin"
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin"
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Configurações",
    icon: "nc-icon nc-settings-gear-65",
    component: Widgets,
    layout: "/admin"
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin"
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin"
  }*/
];

export default routes;
