import React, { Component } from 'react'
import { Modal, Button, Table, Form, FormGroup, Row, Col } from 'reactstrap'
import TagsInput from 'react-tagsinput';
import ReactDatetimeClass from 'react-datetime';
import firebase from 'firebase';

export class Agendar extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            datePicker: '',
            timePicker: '',
            dateTimesArr: [],

            
            nome: props.data.nome,
            telefone: props.data.telefone,
            email: props.data.email,
            status: props.data.status ? props.data.status : 'Sem Contato',
            cidade: props.data.cidade,
            responsavel: props.data.responsavel,
            creci: props.data.creci,

            isLoading: false,
        }
    }
    

    
    handleSubmit = (e) =>{
        e.preventDefault();
        let datePicker = this.state.datePicker;
        let timePicker = this.state.timePicker;
        if(datePicker && timePicker){
            this.setState({isLoading: true})
            let weekDayStr = this.getDayToString(new Date(datePicker).getDay());
            let dateStr =(new Date(datePicker).getDate() + '/'+(new Date(datePicker).getMonth()+1)+'/'+new Date(datePicker).getFullYear()).toString();
            let timeStr = ('0'+new Date(timePicker).getHours()).substr(-2) + ':' + ('0'+new Date(timePicker).getMinutes()).substr(-2)

            firebase.database().ref().child('leads-rh').child(this.props.data.email.toString().split('.').join("")).update(
                {
                    
                    agendamento: weekDayStr + ' '+ dateStr + ' ' + timeStr,
                }, (error) => {
                    if (!error){
                        alert('Agendamento feito com sucesso!')
                        this.setState({isLoading: false});
                        window.location.reload();
                    }
                    else
                        alert(error)
                }
            );
        }else
            alert('Preencha ambos os campos, por favor.')
    }

    getDayToString = (day) => {
        if(day === 0)
            return "Domingo"
        else if(day === 1)
            return "Segunda-Feira"
        else if(day === 2)
            return "Terça-Feira"
        else if(day === 3)
            return "Quarta-Feira"
        else if(day === 4)
            return "Quinta-Feira"
        else if(day === 5)
            return "Sexta-Feira"
        else
            return "Sábado"
    }

    render() {
        return (
            <div>
                <Modal size='lg' isOpen={this.props.isOpen} toggle={this.props.toggleEditModal} onOpened={this.onOpened}>
                    <form style={{margin:'50px', textAlign:'center'}}>
                        <h3>Agendar Visita:</h3>
                        <Row>
                            <Col md='6'>
                                <FormGroup>
                                    <ReactDatetimeClass
                                        timeFormat={false}
                                        onChange={(date) => this.setState({datePicker: date})}
                                        dateFormat={'DD/MM/YYYY'}
                                        inputProps={{placeholder:"Data:"}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <ReactDatetimeClass
                                        dateFormat={false}
                                        onChange={(date) => this.setState({timePicker: date})}
                                        inputProps={{placeholder:"Horário"}}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button type="" color="warning" onClick={this.props.closeModal}>Voltar</Button>
                        <Button type="submit" color="info" onClick={this.handleSubmit} disabled={this.state.loading}>
                            {this.state.loading ? 'Carregando...' : 'Adicionar Horário'}
                        </Button>
                        
                    </form>
                </Modal>
            </div>
        )
    }
}

export default Agendar
