/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import {Link, Redirect} from 'react-router-dom';
import NewUserModal from "./Usuarios/NewUserModal";


class Usuarios extends React.Component {
  DEFAULT_FILTER_METHOD = (filter, row) =>
    String(row[filter.id]).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      .includes(String(filter.value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));

  constructor(props) {
    super(props);
    this.state = {
      isNewUserModalOpen: false,

      filterMethod: this.DEFAULT_FILTER_METHOD,

      data: this.parseUsuarios(this.props.usuariosArray).map((prop, key) => {
        return {
          id: key,
          nome: prop[0],
          apelido: prop[1],
          telefone: prop[2],
          email: prop[3],
          permissoes: prop[4],
          acoes: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a like kind of action */}
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  let obj = this.state.data.find(o => o.id === key);
                  alert(
                    "You've clicked EDIT button on \n{ \nName: " +
                      obj.nome +
                      ", \nome: " +
                      obj.status +
                      ", \nstatus: " +
                      obj.telefone +
                      ", \ntelefone: " +
                      obj.email +
                      ", \nemail: " +
                      obj.responsavel +
                      "\n}."
                  );
                }}
                color="warning"
                size="sm"
                id={'detalhes'+prop[3]}
                className="btn-icon edit"
                disabled
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              
            
              {/* use this button to remove the data row */}
              <Button
                onClick={() => {
                  var data = this.state.data;
                  data.find((o, i) => {
                    if (o.id === key) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      data.splice(i, 1);
                      console.log(data);
                      return true;
                    }
                    return false;
                  });
                  this.setState({ data: data });
                }}
                color="danger"
                size="sm"
                id={'remove'+prop[3]}
                className="btn-icon remove"
                disabled
              >
                <i className="fa fa-trash" />
              </Button>{" "}
              
            </div>
          )
        };
      })
    };
  }

  

  handleAll = (e) => {
    e.preventDefault();
    this.setState({filtered : []});
  }

  handleAgendamento = (e) => {
    e.preventDefault();
    this.setState({filtered : [
      {id:'status', value: 'Agendamento'},
    ]});
  }
  handleContratacoes = (e) => {
    e.preventDefault();
    this.setState({filtered : [
      {id:'status', value: 'Contratado'},
    ]});
  }

  parseUsuarios = (usuarios) => {
    let newUsers = []
    usuarios.map((user) => {
      let arr = [];
      arr.push(user.nome);
      arr.push(user.apelido);
      arr.push(user.telefone);
      arr.push(user.email);
      arr.push(user.permissoes);
      newUsers.push(arr)
    })
    return newUsers
  }

  render() {
    let arrAdms = [];
    let arrAssistentes = [];
    this.state.data.map((user) => {
      if(user.permissoes == 'Administrador')
        arrAdms.push(user)
      else
        arrAssistentes.push(user)
    });
    if(this.props.user.permissoes == 'Administrador')
    return (
      <>
        <div className="content">
        <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-10 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Usuários Totais</p>
                        <CardTitle tag="p">{this.state.data.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Link className="stats"
                    to="/usuarios"
                    onClick={this.handleAll}>
                    <i className="fa fa-address-book" />
                    Ver Todos Os Usuários
                  </Link>
                </CardFooter>
              </Card>
            </Col>

            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-calendar-60 text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Diretores</p>
                        <CardTitle tag="p">
                          {arrAssistentes.length}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>

            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-trophy text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Administradores</p>
                        <CardTitle tag="p">{arrAdms.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>

            
            
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Leads</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    className="-striped -highlight success-pagination"
                    data={this.state.data}
                    resolveData={data => data.map(row => row)}
                    filterable
                    defaultFilterMethod={this.state.filterMethod}
                    
                    previousText= 'Anterior'
                    nextText= 'Próximo'
                    loadingText= 'Carregando...'
                    noDataText= 'Nenhuma Lead Encontrada'
                    pageText= 'Página'
                    ofText= 'de'
                    rowsText= 'leads'

                    
                    filtered={this.state.filtered}
                    onFilteredChange={filtered => this.setState({ filtered })}

                    columns={[
                        {
                          Header: "Nome",
                          accessor: "nome",
                          minWidth: 150,       
                          style: {
                            fontWeight: 'bold'
                          },
                        },
                        {
                          Header: "Apelido",
                          accessor: "apelido",
                          minWidth: 100,  
                        },
                        {
                          Header: "Telefone",
                          accessor: "telefone",
                          style: {
                            fontWeight: ''
                          }
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                          minWidth: 150,                          
                          style: {
                            fontWeight: ''
                          }
                        },
                        {
                          Header: "Permissões",
                          accessor: "permissoes",
                          style: {
                            fontWeight: ''
                          },
                        },
                        {
                          Header: "Ações",
                          accessor: "acoes",
                          sortable: false,
                          filterable: false,
                          
                        },
                      
                    ]}
                    defaultPageSize={10}
                    multiSort={true}
                    showPaginationTop
                    showPaginationBottom={false}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */

                  >
                    
                    {(state, makeTable, instance) => {
                      return(
                        <div>
                          <Button color='primary' onClick={(e) => {
                            e.preventDefault();
                            this.setState({isNewUserModalOpen: true});
                          }}>
                            <i className='fa fa-plus'/>
                              Cadastrar Usuário
                          </Button>
                          {makeTable()}
                        </div>
                      );
                    }}

                  </ReactTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NewUserModal isOpen={this.state.isNewUserModalOpen} user={this.props.user} closeModal={(e) => {
          e.preventDefault();
          this.setState({isNewUserModalOpen: false})
        }}/>
        </div>
      </>
    );
    else
    return(<Redirect to='/' />)
  }
}

export default Usuarios;
