/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  chartOfertaAtiva,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "variables/charts.jsx";

import { Link } from "react-router-dom";
import OverviewBoxView from "./viewComponents/OverviewBoxView";
import StaticChartView from "./viewComponents/StaticChartView";
import StaticBarChartView from "./viewComponents/StaticBarChartView";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.leadsArray,
      assistentes: [],
      usuariosArray: props.usuariosArray,
    };
  }

  getDiretores = (permissoes) => {
    if (this.state.usuariosArray) {
      let filter = this.state.usuariosArray.filter((user) => {
        return user.permissoes == "Diretor";
      });
      let newArr = filter.map((val) => val.apelido);
      return newArr;
    }
  };
  getRhs = (permissoes) => {
    if (this.state.usuariosArray) {
      let filter = this.state.usuariosArray.filter((user) => {
        return user.permissoes == "RH";
      });
      let newArr = filter.map((val) => val.apelido);
      return newArr;
    }
  };

  getLeadsPorDiretorDataset = () => {
    if (
      typeof this.getDiretores()[0] !== "undefined" &&
      typeof this.state.data !== "undefined"
    ) {
      let dataArr = this.getDiretores().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.diretor === assistente
        );

        return leadsArr.length;
      });
      let excludiosArr = this.getDiretores().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.diretor === assistente && lead.excluido === true
        );

        return leadsArr.length;
      });

      let ativosArr = this.getDiretores().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.diretor === assistente && lead.excluido !== true
        );

        return leadsArr.length;
      });
      return [
        { label: "Leads Totais", data: dataArr, backgroundColor: "#79b579" },
        { label: "Excluídos", data: excludiosArr, backgroundColor: "#e37e7b" },
        { label: "Ativos", data: ativosArr, backgroundColor: "#6bbcc2" },
      ];
    } else {
      return [{ label: "Carregando", data: [0] }];
    }
  };

  getLeadsPorRHDataset = () => {
    if (
      typeof this.getRhs()[0] !== "undefined" &&
      typeof this.state.data !== "undefined"
    ) {
      let dataArr = this.getRhs().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.responsavel === assistente
        );

        return leadsArr.length;
      });
      let excludiosArr = this.getRhs().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.responsavel === assistente && lead.excluido === true
        );

        return leadsArr.length;
      });

      let ativosArr = this.getRhs().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.responsavel === assistente && lead.excluido !== true
        );

        return leadsArr.length;
      });
      return [
        { label: "Leads Totais", data: dataArr, backgroundColor: "#79b579" },
        { label: "Excluídos", data: excludiosArr, backgroundColor: "#e37e7b" },
        { label: "Ativos", data: ativosArr, backgroundColor: "#6bbcc2" },
      ];
    } else {
      return [{ label: "Carregando", data: [0] }];
    }
  };

  getVisitasContratacoesDiretoresDataset = () => {
    if (
      typeof this.getDiretores()[0] !== "undefined" &&
      typeof this.state.data !== "undefined"
    ) {
      let dataArr = this.getDiretores().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.diretor === assistente && lead.status === "Visita"
        );

        return leadsArr.length;
      });
      let excludiosArr = this.getDiretores().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.diretor === assistente && lead.status === "Contratado"
        );

        return leadsArr.length;
      });
      return [
        { label: "Visitas", data: dataArr, backgroundColor: "#f0d995" },
        { label: "Contratado", data: excludiosArr, backgroundColor: "#6bbcc2" },
      ];
    } else {
      return [{ label: "Carregando", data: [0] }];
    }
  };
  getVisitasContratacoesRhDataset = () => {
    if (
      typeof this.getRhs()[0] !== "undefined" &&
      typeof this.state.data !== "undefined"
    ) {
      let dataArr = this.getRhs().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) => lead.responsavel === assistente && lead.status === "Visita"
        );

        return leadsArr.length;
      });
      let excludiosArr = this.getRhs().map((assistente) => {
        let leadsArr = this.state.data.filter(
          (lead) =>
            lead.responsavel === assistente && lead.status === "Contratado"
        );

        return leadsArr.length;
      });
      return [
        { label: "Visitas", data: dataArr, backgroundColor: "#f0d995" },
        { label: "Contratado", data: excludiosArr, backgroundColor: "#6bbcc2" },
      ];
    } else {
      return [{ label: "Carregando", data: [0] }];
    }
  };

  render() {
    let arrAgendamentos = [];
    let arrVisitas = [];
    let arrContratacoes = [];
    let excluidos = [];
    this.state.data.map((lead) => {
      if (lead.excluido) excluidos.push(lead);
      else if (lead.status == "Agendamento") arrAgendamentos.push(lead);
      else if (lead.status == "Visita") arrVisitas.push(lead);
      else if (lead.status == "Contratado") arrContratacoes.push(lead);
    });
    return (
      <>
        <div className="content">
          <Row>
            <Col
              lg={this.props.user.permissoes == "Administrador" ? "4" : 3}
              md="6"
              sm="6"
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-10 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Leads Totais</p>
                        <CardTitle tag="p">
                          {
                            this.state.data.filter((lead) => !lead.excluido)
                              .length
                          }
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Link className="stats" to="/leads" onClick={this.handleAll}>
                    <i className="fa fa-address-book" />
                    Ver Todas As Leads
                  </Link>
                </CardFooter>
              </Card>
            </Col>

            <Col
              lg={this.props.user.permissoes == "Administrador" ? "4" : 3}
              md="6"
              sm="6"
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-calendar-60 text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Agendamentos</p>
                        <CardTitle tag="p">{arrAgendamentos.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>

            <Col
              lg={this.props.user.permissoes == "Administrador" ? "4" : 3}
              md="6"
              sm="6"
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-trophy text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Visitas</p>
                        <CardTitle tag="p">{arrVisitas.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>

            <Col
              lg={this.props.user.permissoes == "Administrador" ? "6" : 3}
              md="6"
              sm="6"
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-briefcase text-info" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Contratações</p>
                        <CardTitle tag="p">{arrContratacoes.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
            {this.props.user.permissoes == "Administrador" && (
              <Col lg="6" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="fa fa-trash text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Excluídos</p>
                          <CardTitle tag="p">{excluidos.length}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="6" sm="6">
              <StaticBarChartView
                title="Leads Por RH"
                color="success"
                linkText="Ir para Leads"
                to="/leads"
                labels={this.getRhs()}
                dataset={this.getLeadsPorRHDataset()}
              />
            </Col>
            <Col lg="6" sm="6">
              <StaticBarChartView
                title="Visitas e Contratações Por RH"
                color="info"
                linkText="Ir para Leads"
                to="/leads"
                labels={this.getRhs()}
                labelOne="Minhas Ofertas"
                labelTwo="Média da Equipe"
                dataset={this.getVisitasContratacoesRhDataset()}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6" sm="6">
              <StaticBarChartView
                title="Leads Por Diretor"
                color="success"
                linkText="Ir para Leads"
                to="/leads"
                labels={this.getDiretores()}
                dataset={this.getLeadsPorDiretorDataset()}
              />
            </Col>
            <Col lg="6" sm="6">
              <StaticBarChartView
                title="Visitas e Contratações Por Diretor"
                color="info"
                linkText="Ir para Leads"
                to="/leads"
                labels={this.getDiretores()}
                labelOne="Minhas Ofertas"
                labelTwo="Média da Equipe"
                dataset={this.getVisitasContratacoesDiretoresDataset()}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
