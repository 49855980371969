/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import Select from 'react-select';
import DatetimeRangePicker from 'react-datetime-range-picker';

import {Link} from 'react-router-dom';
import RecycleModal from "./Leads/RecycleModal";
require('moment/locale/pt-br');

class Excluidos extends React.Component {
  DEFAULT_FILTER_METHOD = (filter, row) =>
    String(row[filter.id]).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      .includes(String(filter.value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));

  constructor(props) {
    super(props);
    this.state = {
      statusSelect: '',
      filterMethod: this.DEFAULT_FILTER_METHOD,
      filtered: [],

      modalEditIsOpen: false,
      isNewLeadModalOpen: false,
      isRecycleModalOpen: false,
      isNovoAgendamentoOpen: false,
      isAgendarOpen: false,


      leadsArray: this.parseLeads(this.props.excluidos),

      modalEdit: {
        dbId: '',
        nome: '',
        status: '',
        telefone: '',
        email: '',
        responsavel: '',
        diretor: '',
        origem: '',
        observacoes: '',
        creci: false,
        cidade: '',

      },
      modalRecycleNome: '',
      modalRecycleEmail: '',
      modalNovoAgendamentoNome: '',
      modalNovoAgendamentoEmail: '',

      responsavelArray: props.usuariosArray,
      excluidos: props.excluidos ? props.excluidos : 0,



      data: this.parseLeads(this.props.excluidos).map((prop, key) => {
        return {
          id: key,
          nome: prop[0],
          dbId: prop[1],
          status: prop[2],
          telefone: prop[3],
          email: prop[4],
          responsavel: prop[5],
          origem: prop[6],
          timestamp: prop[7],
          creci: prop[8],
          cidade: prop[9],
          agendamento: prop[10],
          observacoes: prop[11],
          diretor: prop[12],
          acoes: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                    let obj = this.state.data.find(o => o.id === key);
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well

                    this.setState({modalRecycleEmail: obj.email, modalRecycleNome: obj.nome, isRecycleModalOpen: true})
                  
                }}
                color="info"
                size="sm"
                id={'detalhes'+prop[3]}
                className="btn-icon edit"
              >
                <i className="fa fa-recycle" />
              </Button>
              
              
            </div>
          )
        };
      })
    };
  }
  

  handleAll = (e) => {
    e.preventDefault();
    this.setState({filtered : []});
  }

  handleAgendamento = (e) => {
    e.preventDefault();
    this.setState({filtered : [
      {id:'status', value: 'Agendamento'},
    ]});
  }
  handleVisita = (e) => {
    e.preventDefault();
    this.setState({filtered : [
      {id:'status', value: 'Visita'},
    ]});
  }
  handleContratacoes = (e) => {
    e.preventDefault();
    this.setState({filtered : [
      {id:'status', value: 'Contratado'},
    ]});
  }

  handleDateTimeChangeDoDia = (dates) => {
    this.setState({doDia: dates.start, ateODia: dates.end});
    
    if(this.state.filtered){
      let found = this.state.filtered.find(element => element.id == 'timestamp');
      if(found){
        let index = this.state.filtered.indexOf(found);
        let newFiltered = this.state.filtered;
        newFiltered[index] = {id: 'timestamp', value: dates};
        this.setState({filtered: newFiltered})
      }
      else{
        this.setState({filtered: [...this.state.filtered, {id: 'timestamp', value: dates}]});
      }
    }
  }
  handleDateTimeChangeAteODia = (date) => {
    this.setState({ateODia: date});
    
    if(this.state.filtered){
      let found = this.state.filtered.find(element => element.id == 'timestamp');
      if(found){
        let index = this.state.filtered.indexOf(found);
        let newFiltered = this.state.filtered;
        newFiltered[index] = {id: 'timestamp', value: new Date(date).getTime()/1000};
        this.setState({filtered: newFiltered})
      }
      else{
        this.setState({filtered: [...this.state.filtered, {id: 'timestamp', value: new Date(date).getTime()/1000}]});
      }
    }
  }

  colorStatus = (status) => {
      if(status === 'Sem Contato'){
          return 'danger';
      }else if(status === 'Retornar'){
          return 'warning';
      }else if(status === 'Agendamento'){
        return 'info';
      }else if(status === 'Visita'){
          return 'info';
      }else if(status === 'Contratado'){
        return 'success';
      }
  }

  statusFilterSelect = ({filter, onChange}) => {
    return (
      <Select
          className="react-select primary"
          classNamePrefix="react-select"
          name="statusSelect"
          onChange={(value) => {
              if(this.state.filtered){
                let found = this.state.filtered.find(element => element.id == 'status');
                if(found){
                  let index = this.state.filtered.indexOf(found);
                  let newFiltered = this.state.filtered;
                  newFiltered[index] = {id: 'status', value: value.value};
                  this.setState({filtered: newFiltered})
                }
                else{
                  console.log(this.state.filtered)
                  this.setState({filtered: [...this.state.filtered, {id: 'status', value: value.value}]});
                }
              }
            }
          }
          styles={{
            // Fixes the overlapping problem of the component
            menuPortal: provided => ({ ...provided, position:'fixed', zIndex: 9999999999 })
          }}
          options={[
            {
              value: '',
              label: 'Todos',
            },
            { value: "Sem Contato", label: "Sem Contato" },
            { value: "Retornar", label: "Retornar" },
            { value: "Agendamento", label: "Agendamento" },
            { value: "Visita", label: "Visita" },
            { value: "Contratado", label: "Contratado" },
          ]}
          placeholder="Status"
      />
    )
  }

  parseLeads = (leads) => {
    let newLeads = []
    let i = 0;
    leads.filter(l => l.excluido == true).map((lead) => {
      let arr = [];
      arr.push(lead.nome);
      arr.push(i);
      arr.push(lead.status);
      arr.push(lead.phone);
      arr.push(lead.email);
      arr.push(lead.responsavel);
      arr.push(lead.origem);
      arr.push(lead.unixTimestamp);
      arr.push(lead.creci);
      arr.push(lead.city);
      arr.push(lead.agendamento);
      arr.push(lead.observacoes);
      arr.push(lead.diretor);
      newLeads.push(arr)
      i++;
    })
    return newLeads
  }

  render() {
    let arrAgendamentos = [];
    let arrVisitas = [];
    let arrContratacoes = [];
    this.state.data.map((lead) => {
      if(lead.status == 'Agendamento')
        arrAgendamentos.push(lead)
      else if(lead.status == 'Visita')
        arrVisitas.push(lead)
      else if(lead.status == 'Contratado')
        arrContratacoes.push(lead)
    });
    return (
      <>
        <div className="content">
        <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                      <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-trash text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Excluídos</p>
                        <CardTitle tag="p">{this.state.data.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Link className="stats"
                    to="/leads"
                    onClick={this.handleAll}>
                    <i className="fa fa-address-book" />
                    Ver Todas As Descartadas
                  </Link>
                </CardFooter>
              </Card>
            </Col>            
          </Row>


          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Leads Descartadas</CardTitle>
                </CardHeader>

                <CardBody>
                  <ReactTable
                    className="-striped -highlight success-pagination"
                    data={this.state.data}
                    resolveData={data => data.map(row => row)}
                    filterable
                    defaultFilterMethod={this.state.filterMethod}
                    
                    previousText= 'Anterior'
                    nextText= 'Próximo'
                    loadingText= 'Carregando...'
                    noDataText= 'Nenhuma Lead Encontrada'
                    pageText= 'Página'
                    ofText= 'de'
                    rowsText= 'leads'

                    getTheadFilterThProps={(state, rowInfo, column) => {
                      return {
                          style: { overflow: 'visible' }
                      };
                    }}

                    
                    filtered={this.state.filtered}
                    onFilteredChange={filtered => this.setState({ filtered })}

                    columns={[
                        {
                          Header: "Data",
                          accessor: "timestamp",
                          minWidth: 70,
                          filterMethod: (filter, row) => {
                            let date = new Date(this.state.doDia).getTime()/1000;
                            let date2 = new Date(this.state.ateODia).getTime()/1000;
                            if(date){
                              if(date2)
                                return row[filter.id] <= date2
                              else
                                return row[filter.id] >= date
                            }else if(date2){
                              return row[filter.id] <= date2
                            }
                          },
                          Filter: ({filter, onChange}) => 
                            <div>
                              <DatetimeRangePicker
                                  inputProps={{
                                    className: "form-control",
                                  }}
                                  startDate={this.state.doDia}
                                  endDate={this.state.ateODia}
                                  timeFormat={false}
                                  dateFormat={'DD/MM/YYYY'}
                                  locale='pt-br'
                                  closeOnSelect={true}
                                  onChange={this.handleDateTimeChangeDoDia}
                                  isOutsideRange={() => false}
                                  withPortal={true}
                                  showClearDates={true} />

                            </div>,

                          filterMethod: (filter, row) => {
                            let doDia = this.state.doDia.getTime() / 1000;
                            let ateODia = this.state.ateODia.getTime() / 1000;
                            return(row[filter.id] >= doDia && row[filter.id] <= ateODia)
                          },
                          Cell: cellInfo => <p>{cellInfo.value ?
                            new Date(cellInfo.value*1000).getDate().toString()+'/'+
                            (new Date(cellInfo.value*1000).getMonth()+1)+'/'+
                            new Date(cellInfo.value*1000).getFullYear() : ''}</p>,
                        },
                        {
                          Header: "Nome",
                          accessor: "nome",
                          minWidth: 150,       
                          style: {
                            fontWeight: 'bold'
                          },
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                          getProps: (state, rowInfo, column) => {
                              return {
                                  style: {
                                      fontWeight: '',
                                      color: rowInfo && rowInfo.row.status === 'Sem Contato' ? 'red' :
                                          rowInfo && rowInfo.row.status === 'Retornar' ? '#ffe838' :
                                          rowInfo && rowInfo.row.status === 'Agendamento' ? '#0394fc' :
                                          rowInfo && rowInfo.row.status === 'Visita' ? '#0394fc' :
                                          rowInfo && rowInfo.row.status === 'Entrevista' ? '#0394fc' :
                                          rowInfo && rowInfo.row.status === 'Contratado' ? '#3dff57' : null,
                                  },
                              };
                          },
                          Filter: ({filter, onChange}) => this.statusFilterSelect({filter, onChange}),
                          filterMethod: (filter, row) => 
                          String(row[filter.id]).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                          .includes(String(filter.value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                      
                        },
                        {
                          Header: "Telefone",
                          accessor: "telefone",
                          style: {
                            fontWeight: ''
                          },
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                          minWidth: 150,                          
                          style: {
                            fontWeight: ''
                          }
                        },
                        {
                          
                          Header: "Responsável",
                          accessor: "responsavel",
                          minWidth: 50,
                          style: {
                            fontWeight: ''
                          },
                        },
                        {
                          Header: "Diretor",
                          accessor: "diretor",
                          minWidth: 50,
                          style: {
                            fontWeight: ''
                          }
                        },
                        {
                          Header: "Origem",
                          accessor: "origem",
                          style: {
                            fontWeight: ''
                          },
                        },
                        {
                          Header: "Ações",
                          accessor: "acoes",
                          minWidth: 30,
                          sortable: false,
                          filterable: false,
                          
                        },
                      
                    ]}
                    defaultSorted={[
                      {
                        id: "timestamp",
                        desc: true
                      }
                    ]}
                    defaultPageSize={10}
                    multiSort={true}
                    showPaginationTop
                    showPaginationBottom={false}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */

                    SubComponent={(row) => {



                        return(
                            <div>                
                              <Card style={{backgroundColor:'#F7F7F7'}}>
                                <CardHeader>
                                  <Row>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p className=""> 
                                          <small className="text-muted">
                                            Nome:
                                          </small>
                                          <br />
                                          <strong>                              
                                            {this.state.data.find(o => o.dbId === row.index).nome}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p>
                                          <small className="text-muted">
                                            Telefone:
                                          </small>
                                          <br />
                                          <strong>                              
                                            {this.state.data.find(o => o.dbId === row.index).telefone}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p>
                                          <small className="text-muted">
                                            Email:
                                          </small>
                                          <br />
                                          <small>
                                          <strong>                              
                                            {this.state.data.find(o => o.dbId === row.index).email}
                                          </strong>
                                          </small>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p className="text-primary">
                                          <small className="text-muted">                                            
                                            Origem:
                                          </small>
                                          <br />
                                          <strong className="text-warning">        
                                            {this.state.data.find(o => o.dbId === row.index).origem}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p className="text">
                                          <small className="text-muted">
                                            Status:
                                          </small>
                                          <br />
                                          <strong className={'text-'+this.colorStatus(this.state.data.find(o => o.dbId === row.index).status)}>                        
                                            {this.state.data.find(o => o.dbId === row.index).status}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p className="">
                                          <small className="text-muted">
                                            Possui CRECI:
                                          </small>
                                          <br />
                                          <strong>                                            
                                            {this.state.data.find(o => o.dbId === row.index).creci ? 'Sim' : 'Não'}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p>
                                          <small className="text-muted">
                                            Responsável:
                                          </small>
                                          <br />
                                          <strong>                              
                                          {this.state.data.find(o => o.dbId === row.index).responsavel}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p>
                                          <small className="text-muted">
                                            Diretor:
                                          </small>
                                          <br />
                                          <strong>                              
                                          {this.state.data.find(o => o.dbId === row.index).diretor}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm='6' md='3' lg='3'>
                                      <div className="typography-line">
                                      
                                        <p>
                                          <small className="text-muted">
                                            Agendamento:
                                          </small>
                                          <br />
                                          <strong>                              
                                          {this.state.data.find(o => o.dbId === row.index).agendamento}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                        
                                      <Col sm="12" md="12" lg="12" style={{textAlign: 'center'}}>
                                        <Button href={'https://wa.me/55'+this.state.data.find(o => o.dbId === row.index).telefone} target='_blank'
                                        color="success" className="btn-round" style={{float:'center'}}>
                                            <i className="fa fa-whatsapp"></i>
                                            WhatsApp
                                        </Button>
                                      </Col>
                                  </Row>
                                </CardHeader>                                        
                                <hr />
                                <CardBody>
                                  
                                <Row>
                                    <Col sm="6" md="6" lg="3">
                                        
                                      <div className="typography-line">
                                        <p className="text-primary">
                                          <small className="text-muted">
                                            Data de Cadastro no CRM:
                                          </small>
                                          <br />
                                          <strong className="text-secondary">                       
                                            {this.state.data.find(o => o.dbId === row.index).timestamp ?
                                            new Date(this.state.data.find(o => o.dbId === row.index).timestamp*1000).getDate().toString()+'/'+
                                            (new Date(this.state.data.find(o => o.dbId === row.index).timestamp*1000).getMonth()+1)+'/'+
                                            new Date(this.state.data.find(o => o.dbId === row.index).timestamp*1000).getFullYear() : ''}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm="6" md="6" lg="3">
                                        
                                      <div className="typography-line">
                                        <p className="text-primary">
                                          <small className="text-muted">
                                            Observações:
                                          </small>
                                          <br />
                                          <strong className="text-secondary">                       
                                            {this.state.data.find(o => o.dbId === row.index).observacoes}
                                          </strong>
                                        </p>
                                      </div>
                                    </Col>
                                   
                                  </Row>
                                </CardBody> 
                              </Card>
                            </div>
                        )
                    }}
                  >
                    
                    {(state, makeTable, instance) => {
                      return(
                        <div>
                          {makeTable()}
                        </div>
                      );
                    }}

                  </ReactTable>
                </CardBody>
              </Card>
            </Col>
          </Row>

        <RecycleModal user={this.props.user} isOpen={this.state.isRecycleModalOpen} nome={this.state.modalRecycleNome} email={this.state.modalRecycleEmail} excluidos={this.state.excluidos}
          closeModal={(e) => {
            e.preventDefault();
          this.setState({isRecycleModalOpen: false})
        }}/>
        </div>

      </>
    );
  }
}

export default Excluidos;
