import React, { Component } from "react";
import { Modal, FormGroup, Label, Input, Button } from "reactstrap";

import firebase from "../../firebase.js";
import Notify from "react-notification-alert";

export class NewUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      telefone: "",
      email: "",
      apelido: "",
      permissoes: "Diretor",

      isLoading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.nome !== "" &&
      this.state.email !== "" &&
      this.state.telefone !== "" &&
      this.state.apelido !== "" &&
      this.state.permissoes
    ) {
      this.setState({ isLoading: true });

      let self = this;

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, "vamosvender@01")
        .then(function (result) {
          //Here if you want you can sign in the user
          const key = firebase
            .database()
            .ref()
            .child("usuarios")
            .child(self.state.email.toString().split(".").join(""))
            .set(
              {
                nome: self.state.nome,
                email: self.state.email,
                telefone: self.state.telefone,
                apelido: self.state.apelido,
                permissoes: self.state.permissoes,
                password: "vamosvender@01",
                uid: result.user.uid,
                timestamp: new Date().getTime() / 1000,
              },
              (error) => {
                if (error) alert(error);
                else {
                  alert(
                    "Usuário criado com sucesso. Você será deslogado para aplicar as alterações."
                  );
                  self.props.closeModal(e);
                  firebase.auth().signOut();
                  window.location.reload(false);
                }
              }
            ).key;
        })
        .catch(function (error) {
          //Handle error
          if (error.code == "auth/invalid-email") {
            alert("Email Inválido");
            self.setState({ isLoading: false });
          }
          console.log(error);
        });
    } else {
      var options = {
        place: "bc",
        message: "Por favor, preencha todos os campos.",
        type: "warning",
        autoDismiss: 4,
      };
      this.refs.notify.notificationAlert(options);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  render() {
    return (
      <div className="content">
        <Notify ref="notify" />
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggleEditModal}
          onOpened={this.onOpened}
        >
          <form style={{ margin: "50px" }}>
            <h3>Cadastrar Usuário</h3>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="nome">Nome</Label>
                <Input
                  type="text"
                  placeholder="Nome..."
                  value={this.state.nome}
                  onChange={this.handleChange}
                  name="nome"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="nome">Telefone</Label>
                <Input
                  type="phone"
                  placeholder="Telefone..."
                  value={this.state.telefone}
                  onChange={this.handleChange}
                  name="telefone"
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                placeholder="Email..."
                value={this.state.email}
                onChange={this.handleChange}
                name="email"
              />
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="nome">Apelido</Label>
                <Input
                  type="text"
                  placeholder="Apelido..."
                  value={this.state.apelido}
                  onChange={this.handleChange}
                  name="apelido"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="inputState">Permissões</Label>
                <Input
                  type="select"
                  value={this.state.permissoes}
                  onChange={this.handleChange}
                  name="permissoes"
                >
                  <option>Diretor</option>
                  <option>Administrador</option>
                </Input>
              </FormGroup>
            </div>
            <Button type="" color="warning" onClick={this.props.closeModal}>
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={this.handleSubmit}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? "Carregando..." : "Cadastrar"}
            </Button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default NewUserModal;
