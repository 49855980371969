import React, { Component } from 'react'
import {
    Modal,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";

import firebase from '../../firebase.js';

export class EditModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            nome: props.data.nome,
            telefone: props.data.telefone,
            email: props.data.email,
            status: props.data.status ? props.data.status : 'Sem Contato',
            cidade: props.data.cidade,
            responsavel: props.data.responsavel,
            diretor: props.data.diretor,
            observacoes: props.data.observacoes,
            creci: props.data.creci,

            loading:false,
        }
    }

    onOpened = () => {
        console.log(this.props.data);
        this.setState({
            nome: this.props.data.nome,
            telefone: this.props.data.telefone,
            email: this.props.data.email,
            status: this.props.data.status,
            responsavel: this.props.data.responsavel,
            diretor: this.props.data.diretor,
            observacoes: this.props.data.observacoes,
            creci: this.props.data.creci,
        });
    }

    
    handleChange = (e) => {
        let change = {};
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    
    handleSubmit = (e) => {
        e.preventDefault();
        
        if(this.state.nome !== '' &&
            this.state.email !== '' &&
            this.state.telefone !== ''){


        this.setState({loading: true});
        firebase.database().ref().child('leads-rh').child(this.state.email.toString().split('.').join("")).update(
            {
                
                nome: this.state.nome ?  this.state.nome : '',
                telefone: this.state.telefone ? this.state.telefone : '',
                email: this.state.email ?  this.state.email : '',
                status: this.state.status ?  this.state.status : this.props.data.status ? this.props.data.status : 'Sem Contato',
                cidade: this.state.cidade ?  this.state.cidade : '',
                observacoes: this.state.observacoes ?  this.state.observacoes : '',
                responsavel: this.state.responsavel ? this.state.responsavel : '',
                diretor: this.state.diretor ? this.state.diretor : '',
                creci: this.state.creci ? this.state.creci : false,
            }, (error) => {
                if (!error){
                    this.setState({loading: false});
                    window.location.reload();
                }
                else
                    alert(error)
            }
        );
        }else{
            alert('Por favor, preencha todos os dados.')
        }
    }


    render() {
        return (
            <div className='content'>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggleEditModal} onOpened={this.onOpened}>
                    <form style={{margin:'50px',}}>
                        <h3>Editar Lead</h3>
                        <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label for="nome">Nome</Label>
                            <Input type="text"  placeholder="Nome..." value={this.state.nome} onChange={this.handleChange} name='nome' />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Label for="nome">Telefone</Label>
                            <Input type="phone"  placeholder="Telefone..." value={this.state.telefone} onChange={this.handleChange} name='telefone' />
                        </FormGroup>
                        </div>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" placeholder='Email...' value={this.state.email} onChange={this.handleChange} name='email' disabled/>
                        </FormGroup>
                        <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label for="inputState">Responsável</Label>
                            <Input type="select" name="select" defaultValue={this.props.data.responsavel} name='responsavel' onChange={this.handleChange} >
                                <option>Selecionar</option>
                                {
                                    this.props.responsavelArray && this.props.responsavelArray.length > 1 ?
                                        this.props.responsavelArray.filter(us => us.permissoes === 'RH').map((option, key) => {
                                            return(
                                            <option key={key}>{option.apelido}</option>
                                            )
                                        })

                                    : <option>{this.props.user.apelido}</option>
                                }
                            </Input>
                        </FormGroup>
                        
                        <FormGroup className="col-md-6">
                            <Label for="inputState">Diretor</Label>
                            <Input type="select" name="select" defaultValue={this.props.data.diretor} name='diretor' onChange={this.handleChange} >
                                <option>Selecionar</option>
                                {
                                    this.props.responsavelArray && this.props.responsavelArray.length > 1 ?
                                        this.props.responsavelArray.filter(us => us.permissoes === 'Diretor').map((option, key) => {
                                            return(
                                            <option key={key}>{option.apelido}</option>
                                            )
                                        })

                                    : <option>{this.props.user.apelido}</option>
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Label for="inputState">Status</Label>
                            <Input type="select" name="select" defaultValue={this.props.data.status ? this.props.data.status : 'Sem Contato'} name='status' onChange={this.handleChange}>
                                <option>Selecionar</option>
                                <option>Sem Perfil</option>
                                <option>Sem Interesse</option>
                                <option>Sem Contato</option>
                                <option>Retornar</option>
                                <option>Agendado</option>
                                <option>Contratado</option>
                            </Input>
                        </FormGroup>
                        </div>
                        <FormGroup check>
                            <Label className="form-check-label">
                                <Input className="form-check-input" type="checkbox" checked={this.state.creci} onChange={(e) => {
                                    this.handleChange({
                                        target: {
                                            name: e.target.name,
                                            value: e.target.checked,
                                        },
                                        });
                                    }}
                                    name='creci' />
                                Possui Creci
                                <span className="form-check-sign">
                                    <span className="check"></span>
                                </span>
                            </Label>
                        </FormGroup>
                                     
                        <FormGroup>
                            <Label for="email">Observações</Label>
                            <Input type="textarea" placeholder='Observações...' defaultValue={this.props.data.observacoes} onChange={this.handleChange} name='observacoes' />
                        </FormGroup>
                        <Button type="" color="warning" onClick={this.props.closeModal}>Cancelar</Button>
                        <Button type="submit" color="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                            {this.state.loading ? 'Carregando...' : 'Salvar'}</Button>
                    </form>
                </Modal>
            </div>
        )
    }
}

export default EditModal
