import React, { Component } from 'react'
import { Modal, Button, Table, Form, FormGroup, Row, Col } from 'reactstrap'
import TagsInput from 'react-tagsinput';
import ReactDatetimeClass from 'react-datetime';
import firebase from 'firebase';

export class NovoAgendamento extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            datePicker: '',
            timePicker: '',
            dateTimesArr: [],

            isAddLoading: false,
            isRemoveLoading: false,
        }
    }

    componentDidMount() {
        let self = this;
        firebase.database().ref().child('datas-de-agendamento').on('value', (snapshot) => {
            if(snapshot.val()){
                self.setState({dateTimesArr: Object.values(snapshot.val())})
            }
        }, (error) => {
            if(error){
                alert(error);
            }
        })
    }
    

    
    handleSubmit = (e) =>{
        e.preventDefault();
        let datePicker = this.state.datePicker;
        let timePicker = this.state.timePicker;
        if(datePicker && timePicker){
            this.setState({isAddLoading: true})
            let weekDayStr = this.getDayToString(new Date(datePicker).getDay());
            let dateStr =(new Date(datePicker).getDate() + '-'+(new Date(datePicker).getMonth()+1)+'-'+new Date(datePicker).getFullYear()).toString();
            let timeStr = ('0'+new Date(timePicker).getHours()).substr(-2) + ':' + ('0'+new Date(timePicker).getMinutes()).substr(-2)

            firebase.database().ref().child('datas-de-agendamento').child(dateStr+'-'+timeStr).set({
                weekDay: weekDayStr,
                date: dateStr,
                time: timeStr,

            }, (error) => {
                if(error){
                    alert(error);
                    this.setState({isAddLoading: false});
                }else{                    
                    this.setState({isAddLoading: false}, ()=> {
                        alert('Agendamento programado com sucesso!');
                    });
                }
            })
        }else
            alert('Preencha ambos os campos, por favor.')
    }

    getDayToString = (day) => {
        if(day === 0)
            return "Domingo"
        else if(day === 1)
            return "Segunda-Feira"
        else if(day === 2)
            return "Terça-Feira"
        else if(day === 3)
            return "Quarta-Feira"
        else if(day === 4)
            return "Quinta-Feira"
        else if(day === 5)
            return "Sexta-Feira"
        else
            return "Sábado"
    }

    delete = (dateStr, timeStr) => {
        this.setState({isRemoveLoading: true,})
        let self = this;
        firebase.database().ref().child('datas-de-agendamento').child(dateStr+'-'+timeStr).remove((error) => {
            if(error){
                alert(error);
                self.setState({isRemoveLoading: false,})
            }else{
                alert('Data de agendamento removida com sucesso.');      
                self.setState({isRemoveLoading: false,})
            }
        });
    }

    dateTimesList = () => {
        return this.state.dateTimesArr.map((dt, key) => {
            return( 
            <tr key={key}>
                <td>{dt.date.split('-').join('/')}</td>
                <td>{dt.time}</td>
                <td className="text-right">
                    <Button className="btn-icon" color="danger" size="sm" onClick={() => {
                        this.delete(dt.date, dt.time);
                    }} disabled={this.state.isRemoveLoading}>
                        <i className="fa fa-trash" />
                    </Button>
                </td>
            </tr>
            )
        })       
    }

    render() {
        return (
            <div>
                <Modal size='lg' isOpen={this.props.isOpen} toggle={this.props.toggleEditModal} onOpened={this.onOpened}>
                    <form style={{margin:'50px', textAlign:'center'}}>
                        <h3>Horários de Agendamento:</h3>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <ReactDatetimeClass
                                        timeFormat={false}
                                        onChange={(date) => this.setState({datePicker: date})}
                                        dateFormat={'DD/MM/YYYY'}
                                        inputProps={{placeholder:"Nova Data:"}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <ReactDatetimeClass
                                        dateFormat={false}
                                        onChange={(date) => this.setState({timePicker: date})}
                                        inputProps={{placeholder:"Novo Horário"}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                <Button type="submit" color="success" onClick={this.handleSubmit} disabled={this.state.isAddLoading}>
                                    {this.state.isAddLoading ? 'Carregando...' : 'Adicionar Horário'}
                                </Button>
                                </FormGroup>
                            </Col>
                        </Row>




                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Datas</th>
                                    <th>Horários</th>
                                    <th className="text-right">Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.dateTimesList()}  
                            </tbody>
                        </Table>




                        <Button type="" color="warning" onClick={this.props.closeModal}>Voltar</Button>
                        
                    </form>
                </Modal>
            </div>
        )
    }
}

export default NovoAgendamento
