import React, { Component } from "react";
import { Modal, FormGroup, Label, Input, Button } from "reactstrap";

import firebase from "../../firebase.js";
import Notify from "react-notification-alert";
import { Redirect } from "react-router-dom";

export class NewLeadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      telefone: "",
      email: "",
      responsavel: "",
      diretor: "",
      creci: false,
      origem: "",
      observacoes: "",

      isLoading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.nome !== "" &&
      this.state.email !== "" &&
      this.state.telefone !== "" &&
      this.state.responsavel != "Selecionar" &&
      this.state.responsavel != "" &&
      this.state.diretor != "Selecionar" &&
      this.state.diretor != ""
    ) {
      this.setState({ isLoading: true });

      let self = this;

      //Here if you want you can sign in the user
      const key = firebase
        .database()
        .ref()
        .child("leads-rh")
        .child(self.state.email.toString().split(".").join(""))
        .set(
          {
            nome: self.state.nome,
            email: self.state.email,
            phone: self.state.telefone,
            status: "Sem Contato",

            responsavel: self.state.responsavel,
            diretor: self.state.diretor,
            creci: self.state.creci,
            origem: self.state.origem,
            observacoes: self.state.observacoes,
            unixTimestamp: new Date().getTime() / 1000,
          },
          (error) => {
            if (error) alert(error);
            else {
              self.setState({ isLoading: false });
              alert("Lead cadastrada com sucesso!");
              self.props.closeModal(e);
              window.location.reload();
            }
          }
        ).key;
    } else {
      alert("Por favor, preencha todos os campos.");
      this.setState({ isLoading: false });
    }
  };

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  render() {
    return (
      <div className="content">
        <Notify ref="notify" />
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggleEditModal}
          onOpened={this.onOpened}
        >
          <form style={{ margin: "50px" }}>
            <h3>Cadastrar Lead</h3>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="nome">Nome</Label>
                <Input
                  type="text"
                  placeholder="Nome..."
                  value={this.state.nome}
                  onChange={this.handleChange}
                  name="nome"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="nome">Telefone</Label>
                <Input
                  type="phone"
                  placeholder="Telefone..."
                  value={this.state.telefone}
                  onChange={this.handleChange}
                  name="telefone"
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                placeholder="Email..."
                value={this.state.email}
                onChange={this.handleChange}
                name="email"
              />
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="inputState">Responsável</Label>
                <Input
                  type="select"
                  defaultValue={this.state.responsavel}
                  name="responsavel"
                  onChange={this.handleChange}
                >
                  <option>Selecionar</option>
                  {this.props.responsavelArray &&
                  this.props.user.permissoes == "Administrador" ? (
                    this.props.responsavelArray
                      .filter((us) => us.permissoes === "RH")
                      .map((option, key) => {
                        return <option key={key}>{option.apelido}</option>;
                      })
                  ) : (
                    <option>{this.props.user.apelido}</option>
                  )}
                </Input>
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="inputState">Diretor</Label>
                <Input
                  type="select"
                  defaultValue={this.state.diretor}
                  name="diretor"
                  onChange={this.handleChange}
                >
                  <option>Selecionar</option>
                  {this.props.responsavelArray &&
                  this.props.responsavelArray.length > 1 ? (
                    this.props.responsavelArray
                      .filter((us) => us.permissoes === "Diretor")
                      .map((option, key) => {
                        return <option key={key}>{option.apelido}</option>;
                      })
                  ) : (
                    <option>{this.props.user.apelido}</option>
                  )}
                </Input>
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="inputState">Origem</Label>
                <Input
                  type="select"
                  defaultValue={this.state.origem}
                  name="origem"
                  onChange={this.handleChange}
                >
                  <option>Selecionar</option>
                  <option>Indicação</option>
                  <option>LinkedIn</option>
                  <option>Indeed</option>
                  <option>Catho</option>
                  <option>Indeed</option>
                  <option>Vagas.com</option>
                  <option>Empregos.com</option>
                  <option>InfoJobs</option>
                  <option>Facebook Ads</option>
                  <option>Recruta Simples</option>
                  <option>Landing Page</option>
                </Input>
              </FormGroup>
            </div>
            <FormGroup check>
              <Label className="form-check-label">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.creci}
                  onChange={(e) => {
                    this.handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  name="creci"
                />
                Possui Creci
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="email">Observações</Label>
              <Input
                type="textarea"
                placeholder="Observações..."
                value={this.state.observacoes}
                onChange={this.handleChange}
                name="observacoes"
              />
            </FormGroup>
            <Button type="" color="warning" onClick={this.props.closeModal}>
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={this.handleSubmit}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? "Carregando..." : "Cadastrar"}
            </Button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default NewLeadModal;
